<template>
  <div class="main-banner">
    <div class="main-banner__image">
      <div class="main-banner__title">Outdoor recreational rentals</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-banner {
  padding-top: 10px;
}

.main-banner__title {
  font-size: 96px;
  line-height: 1.1;
  font-weight: 700;
  color: #fcfffe;
  text-align: center;
  padding: 80px;
}

.main-banner__image {
  position: relative;
  width: 100%;
  height: 666px;
  background-image: url('@/assets/img/main-banner.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 30px;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('@/assets/img/main-banner-trees-2.png');
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    pointer-events: none;
    margin: 0 -1px -25px;
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('@/assets/img/main-banner-trees-1.png');
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    pointer-events: none;
    margin: 0 -100px;
    z-index: 2;
  }
}

@media screen and (max-width: 1439px) {
  .main-banner__title {
    font-size: 84px;
  }
}

@media screen and (max-width: 1239px) {
  .main-banner__title {
    font-size: 72px;
  }

  .main-banner__image {
    height: 600px;
  }
}

@media screen and (max-width: 899px) {
  .main-banner__title {
    font-size: 64px;
    padding: 40px;
  }

  .main-banner__image {
    height: 500px;
  }
}

@media screen and (max-width: 599px) {
  .main-banner__title {
    font-size: 48px;
    padding: 40px 20px;
  }

  .main-banner__image {
    height: 340px;
  }
}
</style>
